import { Typography } from "antd"
import Image from "next/image"
import { ReactNode, VFC } from "react"
import styled from "styled-components"

type Props = {
  questionText: string
  answerText: ReactNode
}

export const QuestionItem:VFC<Props> = ({ questionText, answerText }) => {
  return (
    <StyledDiv style={{ padding: "16px 0px", display: "flex", flexDirection: "column", gap: "12px" }}>
      <div style={{ display: "flex", alignItems: "start", gap: "8px" }}>
        <div style={{ width: "24px", height: "24px" }}>
          <Image
            src="/lp/question/question.png"
            height={24}
            width={24}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Typography.Text style={{ fontWeight: 700 }}>
            {questionText}
          </Typography.Text>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "start", gap: "8px" }}>
        <div style={{ width: "24px", height: "24px" }}>
          <Image
            src="/lp/question/answer.png"
            height={24}
            width={24}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Typography.Text style={{ fontWeight: 700 }}>
            {answerText}
          </Typography.Text>
        </div>
      </div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  :not(:first-child) {
    border-top: 1px solid #ECECEC
  }
`
