import { Typography } from "antd"
import Link from "next/link"
import { VFC } from "react"

type Props = {
  link: string
  imageUrl: string
  title: string
  companyName: string
  representativeName: string
}
export const CompanyCaseCard: VFC<Props> = ({ link, imageUrl, title, companyName, representativeName }) => {
  return(
    <div style={{ position: "relative", display: "flex", flexDirection: "column", textAlign: "left", gap: "8px", flex: 1 }}>
      <Link href={link}>
        <a>
          <img
            src={imageUrl}
            alt={imageUrl}
            width="100%"
            height="auto"
          />
        </a>
      </Link>
      <Link href={link}>
        <a>
          <Typography.Title level={3}>
            {title}
          </Typography.Title>
        </a>
      </Link>
      <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        <Typography.Text style={{ fontSize: "12px", fontWeight: 700 }}>
          {companyName}
        </Typography.Text>
        <Typography.Text style={{ fontSize: "12px", fontWeight: 700 }}>
          {representativeName}
        </Typography.Text>
      </div>
    </div>
  )
}
