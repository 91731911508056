import { Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import Image from "next/image"

import { FeatureCard } from "./parts/FeatureCard"

export const FeatureSectionForLp = () => {
  const { xs } = useBreakpoint()

  return (
    <div style={{ textAlign: "center", padding: xs ? "64px 16px 80px" : "64px 16px 100px", position: "relative" }} >
      <Typography.Title level={1} id="features" style={{ paddingTop: xs ? "24px" : "80px", marginTop: xs ? "-24px" : "-80px" }}>
        機能一覧
      </Typography.Title>
      <Typography.Title level={3} style={{ marginTop: "16px" }}>
        主要機能を掲載しています。<br />
        新機能も毎月追加していますので、
        {xs && <br />}
        詳しくは弊社担当からご説明します。
      </Typography.Title>
      <div style={{ maxWidth: "857px", margin: "40px auto 0px" }}>
        <div style={{ display: "flex", flexDirection: xs ? "column" : "row", gap: "24px" }}>
          <FeatureCard title="情報管理" featureTexts={["顧客", "アーティスト", "作品", "取引", "イベント"]}/>
          <FeatureCard title="書類ワンクリック発行" featureTexts={["郵送用ラベル", "証明書", "作品カタログ", "作品リスト", "請求書", "仕入伝票", "受託伝票", "納品書"]}/>
          <FeatureCard title="オンライン" featureTexts={["作品販売", "メールマガジン", "顧客とのメッセージ"]}/>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: xs ? undefined : 91,
          right: xs ? 16 : undefined,
          width: xs ? 160 : 200,
          height: xs ? 160 : 200
        }}
      >
        <Image
          src="/lp/feature/Illust_Function.png"
          alt="Illust_Function.png"
          width={xs ? 160 : 200}
          height={xs ? 160 : 200}
        />
      </div>
    </div>
  )

}
