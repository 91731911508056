import Image from "next/image"
import { FC } from "react"

export const ImagePart: FC = () => {

  return (
    <div style={{ position: "relative", flex: 1, height: "100%", width: "100%" }}>
      <Image
        src="/lp/key-visual-for-lp.png"
        alt="key-visual-for-lp"
        layout="fill"
        objectFit="contain"
      />
    </div>
  )
}

