import { Button, Drawer as AntDesignDrawer, Typography } from "antd"
import colors from "helpers/colors"
import { urls } from "helpers/urls"
import Link from "next/link"
import { useMemo, VFC } from "react"
import styled from "styled-components"

type Props = {
  isOpenDrawer: boolean
  onClose: ()=> void
}

export const DrawerForLp:VFC<Props> = ({ isOpenDrawer, onClose }) => {

  const menuItems = useMemo(() => [
    {
      link: urls.lp,
      text: "トップ"
    },
    // {
    //   link: urls.lp("features"),
    //   text: "機能紹介"
    // },
    {
      link: urls.lp_case,
      text: "導入事例"
    },
  ], [])

  return (
    <AntDesignDrawer
      title={null}
      placement="right"
      closeIcon={null}
      onClose={onClose}
      visible={isOpenDrawer}
      bodyStyle={{ padding: "0px", display: "flex", flexDirection: "column" }}
      contentWrapperStyle={{ width: "80%" }}
      headerStyle={{
        border: "none",
        padding: "10px",
        height: "24px"
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", marginBottom: "24px" }}>
        {menuItems.map((menuItem, index) => {
          return (
            <Link href={menuItem?.link || "/"} key={index}>
              <a>
                <StyledDiv style={{ height: "45px", padding: "0px 20px", display: "flex", alignItems: "center" }} onClick={onClose}>
                  <Typography.Text style={{ fontSize: "14px" }}>
                    {menuItem?.text}
                  </Typography.Text>
                </StyledDiv>
              </a>
            </Link>
          )
        })}
        <a href="https://cloud.artx.com/sign_in" target="_blank" rel="noreferrer">
          <StyledDiv style={{ height: "45px", padding: "0px 20px", display: "flex", alignItems: "center" }} onClick={onClose}>
            <Typography.Text style={{ fontSize: "14px" }}>
              ログイン
            </Typography.Text>
          </StyledDiv>
        </a>
      </div>
      <div style={{ padding: "24px 16px", display: "flex", flexDirection: "column", gap: "16px", borderTop: "1px solid #C4C4C4", borderBottom: "1px solid #C4C4C4" }}>
        <Link href={urls.contact_for_lp}>
          <StyledPrimaryButton type="primary" style={{ width: "100%", fontSize: "16px", lineHeight: 1.5, fontWeight: 700 }}>
            お問い合わせ
          </StyledPrimaryButton>
        </Link>
        <Link href={urls.document_request_for_lp}>
          <StyledDefaultButton type="default" style={{ width: "100%", fontSize: "16px", lineHeight: 1.5, fontWeight: 700 }}>
            資料請求
          </StyledDefaultButton>
        </Link>
      </div>
    </AntDesignDrawer>
  )
}

const StyledDiv = styled.div`
:hover{
  background-color: #F5F5F5;
}

`

const StyledDefaultButton = styled(Button)`
  height: 48px;
  border-radius: 40px;
  border-color: ${colors.primary};

  &:hover {
    background: ${colors.primary};
    color: white;
  }
`

const StyledPrimaryButton = styled(Button)`
  height: 48px;

  border-radius: 40px;

  &:hover {
   color: ${colors.primary};
   background: white;
  }
`
