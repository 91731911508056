import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import Image from "next/image"
import { FC } from "react"

import { ImagePart } from "./parts/ImagePart"
import { TextPart } from "./parts/TextPart"

export const EyeCatchForLp: FC = () => {
  const { xs } = useBreakpoint()

  return (
    <div style={{ width: "100%", height: 477, position: "relative", padding: "60px 48px" }}>
      <Image
        src="/lp/bg-for-lp.svg"
        alt="cta background"
        layout="fill"
        objectFit="cover"
      />
      <div
        style={{
          position: "absolute",
          top: 0, bottom: 0, right: 0, left: 0,
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
          flexDirection: xs ? "column-reverse" : "row",
          padding: xs ? "36px 18px 32px 14px" : "36px 60px 24px 64px",
          gap: "23px"
        }}
      >
        <TextPart />
        <ImagePart />
      </div>
    </div>
  )
}

