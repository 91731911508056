import { Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import { BreadcrumbsForLp } from "components/shared/Breadcrumbs"
import { Entry } from "contentful"
import { urls } from "helpers/urls"
import { generateReactComponentFormRichTextDocument, IArticle } from "libs/contentful"
import { VFC } from "react"

type Props = {
  article: Entry<IArticle>
}

export const CompanyCaseDetail: VFC<Props> = ({ article }) => {
  const { xs } = useBreakpoint()

  return (
    <div style={{ padding: xs ? "48px 16px" : "80px 16px", background: "#ECECEC", position: "relative", overflow: "hidden" }} id="company_cases">
      <div
        style={{
          position: "absolute",
          background: "#E5E5E5",
          width: xs ? "100%" : "1069px",
          height: xs ? "336.69px" : "876px",
          top: "0px",
          right: "0px",
          clipPath: "polygon(0% 0%, 100% 0%, 100% 100%)" }}
      />
      {!xs && (
        <div
          style={{
            position: "absolute",
            padding: "24px 64px",
            top: "0px",
            left: "0px",
          }}
        >
          <BreadcrumbsForLp
            breadcrumbs={[
              { text: "トップ", href: urls.lp },
              { text: "導入事例", href: urls.lp_case },
              { text: article.fields.companyName },
            ]}
          />
        </div>
      )}
      <div style={{ margin: "0 auto", maxWidth: "858px", position: "relative", textAlign: "center" }}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", rowGap: "16px", maxWidth: "600px", margin: "0 auto" }}>
          <Typography.Title level={1}>
            {article.fields.title}
          </Typography.Title>
          <div style={{ display: "flex", columnGap: "16px" }}>
            <Typography.Title level={3}>
              {article.fields.companyName}
            </Typography.Title>
            <Typography.Title level={3}>
              {article.fields.representativeName}
            </Typography.Title>
          </div>
        </div>
        <div style={{ marginTop: "40px", padding: xs ? "32px 16px" : "48px 64px", background: "white", textAlign: "left", display: "flex", flexDirection: "column", rowGap: "24px" }}>
          {generateReactComponentFormRichTextDocument(article.fields.contents)}
        </div>
      </div>
    </div>
  )
}

