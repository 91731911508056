import { Typography } from "antd"
import Image from "next/image"
import { VFC } from "react"

type Props = {
  title: string
  featureTexts: string[]
}

export const FeatureCard: VFC<Props> = ({ title, featureTexts }) => {
  return (
    <div style={{ flex: 1, padding: "24px", background: "#F5F5F5", textAlign: "left" }}>
      <Typography.Title level={3}>
        {title}
      </Typography.Title>
      <div style={{ display: "flex", flexDirection: "column", gap: "4px", marginTop: "16px" }}>
        {featureTexts.map((featureText, index) => {
          return (
            <div key={index} style={{ display: "flex", gap: "4px" }}>
              <Image
                src="/lp/feature/Subtract.svg"
                alt="subtract"
                width={12}
                height={12}
              />
              <Typography.Text style={{ fontWeight: 700 }}>
                {featureText}
              </Typography.Text>
            </div>
          )
        })}
      </div>
    </div>
  )
}
