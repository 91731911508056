import { Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import { VFC } from "react"

import { IntroductionStep } from "./parts/IntroductionStep"

export const IntroductionFlowSectionForLp: VFC = () => {
  const { lg } = useBreakpoint()

  return (
    <div style={{ padding: "80px 0px" }}>
      <div style={{ margin: "0 auto", width: "fit-content", position: "relative", textAlign: "center" }}>
        <Typography.Title level={1}>
          導入までの流れ
        </Typography.Title>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: lg ? "48px" : "24px", marginTop: "48px" }}>
          <div style={{ display: "flex", flexDirection: lg ? "row" : "column", gap: lg ? "0px" : "16px", alignItems: "center" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <IntroductionStep number="01">
                お問い合わせ <br />or資料請求
              </IntroductionStep>
              <div style={{ width: lg ? "43px" : "8px", border: "1px solid #F5F5F5" }}/>
              <IntroductionStep number="02">
                デモンスト<br />レーション
              </IntroductionStep>
              <div style={{ width: lg ? "43px" : "8px", border: "1px solid #F5F5F5" }}/>
              <IntroductionStep number="03">
                ご契約と<br />アカウント開設
              </IntroductionStep>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              {lg && <div style={{ width: "43px", border: "1px solid #F5F5F5" }}/>}
              <IntroductionStep number="04">
                {!lg ? <>既存管理<br />システムからの<br />データ移行</> : <>既存管理システム<br />からのデータ移行</>}
              </IntroductionStep>
              <div style={{ width: lg ? "43px" : "16px", border: "1px solid #F5F5F5" }}/>
              <IntroductionStep number="05">
                運用開始
              </IntroductionStep>
            </div>
          </div>
          <div style={{ paddingRight: lg ? "74px" : "33px", width: "100%", display: "flex" }}>
            <div style={{ background: "#E4F1FF", flex: 1, position: "relative" }}>
              <Typography.Title level={3}>
                最短1週間
              </Typography.Title>
              <div
                style={{
                  background: "#E4F1FF",
                  clipPath: "polygon(100% 50%, 0% 100%, 0% 0%)",
                  width: lg ? "27px" : "24px",
                  height: lg ? "39px" : "36px",
                  position: "absolute",
                  bottom: lg ? "-8px" : "-8px",
                  left: "100%"
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

