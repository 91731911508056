import { InstagramOutlined, TwitterOutlined } from "@ant-design/icons"
import { Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import colors from "helpers/colors"
import { urls } from "helpers/urls"
import Image from "next/image"
import FacebookIcon from "public/icons/facebook.svg"
import { VFC } from "react"
import styled from "styled-components"

export const FooterForLp: VFC = () => {
  const { lg } = useBreakpoint()

  return (
    <div
      style={{
        padding: !lg ? "48px 16px" : "40px 64px",
        backgroundColor: colors.black,
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: !lg ? "column" : "row",
          justifyContent: "space-between",
          gap: !lg ? 32 : 12,
        }}
      >
        <div style={{
          width: "285px"
        }}>
          <div style={{
            gap: !lg ? "8px" : "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}>
            <div style={{ marginRight: "10px", height: "24px" }}>
              <Image src={"/lp/artX_logo_white.svg"} height={24} width={123} />
            </div>
            <StyledTypography type="secondary" style={{ fontSize: "10px", lineHeight: "21px", fontWeight: 500 }}>
              © aisaac inc. all rights reserved.
            </StyledTypography>
          </div>
        </div>
        <div style={{
          flex: 1,
          rowGap: "12px",
          display: "flex",
          gap: !lg ? "40px" : "24px"
        }}>
          <div style={{
            maxWidth: "269px",
            flex: 1,
            rowGap: "12px",
            display: "flex",
            flexDirection: "column",
          }}>
            <StyledTypography strong style={{ fontSize: !lg ? "14px" : "16px" }}>
              私たちについて
            </StyledTypography>
            <StyledAnchor target={"_blank"} href="https://aisaac.jp" style={{ fontSize: !lg ? "12px" : "16px" }}>
              会社概要
            </StyledAnchor>
            <StyledAnchor target={"_blank"} href="https://cloud.artx.com/terms" style={{ fontSize: !lg ? "12px" : "16px" }}>
              利用規約
            </StyledAnchor>
            <StyledAnchor target={"_blank"} href="https://cloud.artx.com/privacy" style={{ fontSize: !lg ? "12px" : "16px" }}>
              プライバシーポリシー
            </StyledAnchor>
            <StyledAnchor target={"_blank"} href="https://aisaac.jp/terms/cookie_policy" style={{ fontSize: !lg ? "12px" : "16px" }}>
              クッキーポリシー
            </StyledAnchor>
            <StyledAnchor target={"_blank"} href={urls.help_center_for_lp} style={{ fontSize: !lg ? "12px" : "16px" }}>
              ヘルプセンター
            </StyledAnchor>
          </div>
          <div style={{
            maxWidth: "285px",
            rowGap: "12px",
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}>
            <StyledTypography strong style={{ fontSize: !lg ? "14px" : "16px" }}>
              サービス
            </StyledTypography>
            <StyledAnchor target={"_blank"} href="/" style={{ fontSize: !lg ? "12px" : "16px" }}>
              ArtX
            </StyledAnchor>
            <StyledAnchor target={"_blank"} href="/about/cloud" style={{ fontSize: !lg ? "12px" : "16px" }}>
              ArtXCloud
            </StyledAnchor>
          </div>
        </div>
        <div style={{
          width: "135px",
          rowGap: "12px",
          display: "flex",
          flexDirection: "column",
          alignItems: !lg ? "flex-start" : "flex-end",
        }}>
          <StyledIconsWrapper style={{ display: "flex", gridGap: "24px" }}>
            <a href='https://www.instagram.com/theartxcollective/' target='_blank' rel="noreferrer" >
              <InstagramOutlined style={{ width: "25px", height: "25px" }}/>
            </a>
            <a href='https://www.facebook.com/theartxofficial' target='_blank' rel="noreferrer">
              <FacebookIcon style={{ width: "25px", height: "25px" }} />
            </a>
            <a href='https://twitter.com/theArtXofficial' target='_blank' rel="noreferrer" >
              <TwitterOutlined style={{ width: "25px", height: "25px" }}/>
            </a>
          </StyledIconsWrapper>
        </div>
      </div>
    </div>
  )
}

const StyledTypography = styled(Typography.Text)`
  &.ant-typography{
    color: #fff
  }
`

const StyledAnchor = styled.a`
  color: white;
  :hover{
    color: white;
    opacity: .8
  }
`

const StyledIconsWrapper = styled.div`
  img, svg {
    cursor: pointer;
    color: white;
    height: 25px;
    width: 25px;
    :hover{
      opacity: .8
    }
  }
`
