import { Button } from "antd"
import colors from "helpers/colors"
import { urls } from "helpers/urls"
import Link from "next/link"
import { VFC } from "react"
import styled from "styled-components"

export const FixedFooterForLp: VFC = () => {
  return (
    <div style={{ width: "100%", background: "white", position: "sticky", bottom: 0, padding: "12px 16px" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
        <Link href={urls.document_request_for_lp}>
          <StyledDefaultButton type="default" style={{ fontSize: "12px", lineHeight: 1, fontWeight: 700, flex: 1 }}>
            資料請求
          </StyledDefaultButton>
        </Link>
        <Link href={urls.contact_for_lp}>
          <StyledPrimaryButton type="primary" style={{ fontSize: "12px", lineHeight: 1, fontWeight: 700, flex: 1 }}>
            お問い合わせ
          </StyledPrimaryButton>
        </Link>
      </div>
    </div>
  )
}

const StyledDefaultButton = styled(Button)`
  height: 36px;
  border-radius: 40px;
  border-color: ${colors.primary};

  &:hover {
    background: ${colors.primary};
    color: white;
  }
`

const StyledPrimaryButton = styled(Button)`
  height: 36px;
  border-radius: 40px;

  &:hover {
   color: ${colors.primary};
   background: white;
  }
`
