import { Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import { VFC } from "react"

import { CommitmentToSecurityCard } from "./parts/CommitmentToSecurityCard"

export const CommitmentToSecuritySectionForLp: VFC = () => {
  const { xs } = useBreakpoint()

  return (
    <div
      style={{
        padding: "80px 16px",
        textAlign: "center",
        background: "#ECECEC",
        position: "relative"
      }}
    >
      <div
        style={{
          position: "absolute",
          background: "#E5E5E5",
          width: "100%",
          height: "1001px",
          bottom: "0px",
          right: "0px",
          clipPath: "polygon( 100% 0%, 100% 100%, 0% 100%)" }}
      />
      <div style={{ display: "flex", flexDirection: "column", gap: "16px", position: "relative" }}>
        <Typography.Title>
          セキュリティーへの取り組み
        </Typography.Title>
        <Typography.Title level={3}>
          ArtXCloudでは、お客さまの情報や資産を保護するために、セキュリティ対策の強化に取り組んでいます。
          {!xs && <br />}
          厳しいセキュリティスタンダードを満たしたものだけ、リリースをしています。
        </Typography.Title>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "24px", margin: "40px auto 0px", maxWidth: "858px", position: "relative" }}>
        <div style={{ display: "flex", flexDirection: xs ? "column" : "row", gap: "24px" }}>
          <CommitmentToSecurityCard
            title="SSL/暗号化全対応"
            imagePath="/lp/security/Illust_Security_01.png"
            description="ArtXCloudの通信はSSL/TLSを用いた暗号化通信で暗号化されており、通信が傍受されてしまうリスクは極めて低いものとなっています。"
          />
          <CommitmentToSecurityCard
            title="SLA (サービス品質保証)"
            imagePath="/lp/security/Illust_Security_02.png"
            description="サービスを提供事業者とその利用者の間で結ばれるサービスのレベルに関する合意サービス水準、サービス品質を保証します。"
          />
        </div>
        <div style={{ display: "flex", flexDirection: xs ? "column" : "row", gap: "24px" }}>
          <CommitmentToSecurityCard
            title="データ保全・保護"
            imagePath="/lp/security/Illust_Security_03.png"
            description="システムのバックアップ、更新履歴の保持を行い、万が一の障害等が発生した場合も復元可能な対策をとっています。"
          />
          <CommitmentToSecurityCard
            title="リリース前テスト"
            imagePath="/lp/security/Illust_Security_04.png"
            description="主要な機能リリースの前には、プロダクトセキュリティチームによるリリース前テストが行われます。テストで、潜在的な脆弱性を発見し取り除きます。"
          />
        </div>
      </div>
    </div>
  )
}
