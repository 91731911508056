import { Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import Image from "next/image"
import { VFC } from "react"

export const PriceSectionForLp: VFC = () => {
  const { xs } = useBreakpoint()
  return (
    <div style={{ padding: xs ? "80px 16px" : "80px 0px", background: "#232427", textAlign: "center", position: "relative" }}>
      <div
        style={{
          position: "absolute",
          background: "#4E4E4F",
          width: xs ? "100%" : "676px",
          height: "100%",
          top: "0px",
          left: "0px",
          clipPath: "polygon(0% 0, 100% 100%, 0% 100%)"
        }}
      />
      <div
        style={{
          position: "absolute",
          background: "black",
          width: xs ? "287px" : "982px",
          height: xs ? "calc(100% - 80px)" : "calc(100% - 118px)",
          top: xs ? "80px" : "118px",
          right: "0px",
          clipPath: "polygon(100% 0%, 100% 100%, 0% 100%)"
        }}
      />
      <Typography.Title level={1} style={{ color: "white" }}>
        ご利用料金
      </Typography.Title>
      <div style={{ maxWidth: "854px", margin: "40px auto 0px", padding: xs ? "40px 16px" : "40px", background: "white", position: "relative" }}>
        <Typography.Title level={3}>
          ArtXCloudの利用料金は、初期費用と月額費用で構成されます。<br/>
          お客様ごとに最適な料金プランをご案内しますので、お気軽にお問い合わせください。
        </Typography.Title>
        <div style={{ display: "flex", alignItems: "center", flexDirection: xs ? "column" : "row", gap: "24px", marginTop: "40px" }}>
          <div style={{ padding: "16px 24px", background: "#F5F5F5", display: "flex", flexDirection: "column", gap: "12px", flex: 1 }}>
            <Typography.Title level={3}>
              初期費用
            </Typography.Title>
            <Typography.Text style={{ fontWeight: 700, textAlign: "start", lineHeight: 1.5 }}>
              アカウント発行やデータ移行、オリジナルのPDFフォーマットを構築するためにかかる費用です。専任コンサルタントによる、導入支援も行います。
            </Typography.Text>
          </div>
          <div style={{ height: "24px", width: "24px" }}>
            <Image src={"/lp/add.svg"} height={24} width={24} />
          </div>
          <div style={{ padding: "16px 24px", background: "#F5F5F5", display: "flex", flexDirection: "column", gap: "12px", flex: 1, alignSelf: "stretch" }}>
            <Typography.Title level={3}>
              月額費用
            </Typography.Title>
            <Typography.Text style={{ fontWeight: 700, textAlign: "start", lineHeight: 1.5 }}>
              ユーザー数やデータ量によって最適な月額費用を設定します。
            </Typography.Text>
          </div>
        </div>
      </div>
    </div>
  )

}
