import { Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import Image from "next/image"
import { VFC } from "react"

type Props = {
  imagePath: string;
  ballonText: string;
  title1: string;
  title2: string;
  description: string;
}

export const ResolveCard: VFC<Props> = ({ imagePath, ballonText, title1, title2, description }) => {
  const { xs } = useBreakpoint()

  return (
    <div
      style={{
        padding: xs ? "32px 16px 24px" : "40px",
        display: "flex",
        flexDirection: xs ? "column" : "row",
        gap: xs ? "16px" : "40px",
        position: "relative",
        alignItems: xs ? "center" : "start",
        background: "white"
      }}
    >
      <div
        style={{
          padding: xs ? "8px 0px" : "12px 0px",
          position: "absolute",
          top: xs ? "-19px" : "-29px",
          left: xs ? "16px" : "208px",
          right: xs ? "16px" : "105px",
          background: "#C4C4C4"
        }}
      >
        <Typography.Title level={3}>
          {ballonText}
        </Typography.Title>
        <div
          style={{
            position: "absolute",
            top: xs ? "31px" : "42px",
            left: xs ? "11px" : "21px",
          }}
        >
          <Image src="/lp/resolve/Ballon.png" height="24px" width="24px" />
        </div>
      </div>
      <div style={{ height: "160px", width: "160px" }}>
        <Image src={imagePath} height="160px" width="160px" />
      </div>
      <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: xs ? "12px" : "16px", textAlign: "left" }}>
        <Typography.Title level={2}>
          {title1}
          {!xs && <br/>}
          {title2}
        </Typography.Title>
        <Typography.Title level={3}>
          {description}
        </Typography.Title>
      </div>
    </div>
  )
}
