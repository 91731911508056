import { Button } from "antd"
import colors from "helpers/colors"
import { urls } from "helpers/urls"
import Link from "next/link"
import { FC } from "react"
import styled from "styled-components"

export const Buttons: FC = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "24px", marginLeft: "auto" }}>
      <Link href={urls.document_request_for_lp}>
        <StyledDefaultButton type="default" style={{ fontSize: "16px", lineHeight: 1.5, fontWeight: 700 }}>
          資料請求
        </StyledDefaultButton>
      </Link>
      <Link href={urls.contact_for_lp}>
        <StyledPrimaryButton type="primary" style={{ fontSize: "16px", lineHeight: 1.5, fontWeight: 700 }}>
          お問い合わせ
        </StyledPrimaryButton>
      </Link>
    </div>
  )
}

const StyledDefaultButton = styled(Button)`
  height: 48px;
  width: 144px;
  border-radius: 40px;
  border-color: ${colors.primary};

  &:hover {
    background: ${colors.primary};
    color: white;
  }
`

const StyledPrimaryButton = styled(Button)`
  height: 48px;
  width: 144px;
  border-radius: 40px;

  &:hover {
   color: ${colors.primary};
   background: white;
  }
`
