import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, Document, MARKS } from "@contentful/rich-text-types"
import { Typography } from "antd"
import { ReactNode } from "react"

export const generateReactComponentFormRichTextDocument = (richTextDocument: Document): ReactNode => {
  return documentToReactComponents(richTextDocument, {
    renderMark: {
      [MARKS.BOLD]: (text) => <span style={{ fontWeight: 700 }}>{text}</span>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (_node, children) => {
        return <Typography.Title style={{ color: "#232427" }}>{children}</Typography.Title>
      },
      [BLOCKS.HEADING_2]: (_node, children) => {
        return <Typography.Title level={2} style={{ fontSize: "20px", lineHeight: 1.5, color: "#232427" }}>{children}</Typography.Title>
      },
      [BLOCKS.HEADING_3]: (_node, children) => {
        return <Typography.Title level={3} style={{ fontSize: "16px", lineHeight: 1.5, color: "#232427" }}>{children}</Typography.Title>
      },
      [BLOCKS.PARAGRAPH]: (_node, children) => {
        return <Typography.Text style={{ fontSize: "16px", lineHeight: 1.8, color: "#232427" }}>{children}</Typography.Text>
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // render the EMBEDDED_ASSET as you need
        return (
          <img
            src={`https://${node.data.target.fields.file.url}`}
            height="auto"
            width="100%"
            alt={node.data.target.fields.description}
          />
        )
      },
    }
  })
}
