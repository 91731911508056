import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import { logoUrls } from "helpers/logoUrls"
import { times } from "lodash"
import { useCallback, useMemo, VFC } from "react"
import styled, { keyframes } from "styled-components"

export const LogoSectionForLp: VFC = () => {
  const { xs } = useBreakpoint()

  const oddLogoUrls = useMemo(() => {
    return logoUrls.filter((_, index) => index % 2 === 1)
  }, [])
  const evenLogoUrls = useMemo(() => {
    return logoUrls.filter((_, index) => index % 2 === 0)
  }, [])

  const fadeIn = useCallback(() => {
    return keyframes`
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-100%);
      }
    `
  }, [])

  const SlideshowContainer = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;
    animation: ${fadeIn()} 25s linear infinite;
  `

  return (
    <div style={{ padding: xs ? "48px 0px" : "64px 0px", display: "flex", flexDirection: "column", gap: "24px", overflow: "hidden" }}>
      <div style={{ display: "inline-flex", width: "135%", overflow: "hidden", flexWrap: "nowrap", marginLeft: "-35%" }}>
        {times(2, () => (
          <SlideshowContainer>
            {oddLogoUrls.map((url, index) => {
              return (
                <li key={index} style={{ margin: "0 2rem" }}>
                  <img
                    key={`${url}-${index}`}
                    src={`/lp/logos/${url}`}
                    alt={url}
                    width={xs ? 140 : 165}
                    height={xs ? 42 : 49.5}
                    style={{ objectFit: "contain", maxWidth: "none" }}
                  />
                </li>
              )
            }
            )}
          </SlideshowContainer>
        ))}
      </div>
      <div style={{ display: "inline-flex", width: "100%", overflow: "hidden", flexWrap: "nowrap" }}>
        {times(2, () => (
          <SlideshowContainer>
            {evenLogoUrls.map((url, index) => {
              return (
                <li key={index} style={{ margin: "0 2rem" }}>
                  <img
                    key={`${url}-${index}`}
                    src={`/lp/logos/${url}`}
                    alt={url}
                    width={xs ? 140 : 165}
                    height={xs ? 42 : 49.5}
                    style={{ objectFit: "contain", maxWidth: "none" }}
                  />
                </li>
              )
            }
            )}
          </SlideshowContainer>
        ))}
      </div>
    </div>
  )
}
