import { Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import Image from "next/image"
import { VFC } from "react"

type Props = {
  title: string
  imagePath: string
  description: string
}

export const CommitmentToSecurityCard:VFC<Props> = ({ title, imagePath, description }) => {
  const { xs } = useBreakpoint()

  return (
    <div
      style={{
        padding: xs ? "32px 24px" : "40px 24px",
        alignItems: "center",
        background: "white",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        gap: xs ? "16px" : "24px",
        textAlign: "left"
      }}
    >
      <Typography.Title level={2}>
        {title}
      </Typography.Title>
      <div style={{ height: xs ? "200px" : "240px", width: xs ? "200px" : "240px" }}>
        <Image src={imagePath} height={xs ? "200px" : "240px"} width={xs ? "200px" : "240px"} />
      </div>
      <Typography.Title level={3}>
        {description}
      </Typography.Title>
    </div>
  )
}
