import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import { urls } from "helpers/urls"
import Image from "next/image"
import Link from "next/link"
import MenuFilledIcon from "public/icons/menuFilled.svg"
import { FC, useState } from "react"

import { DrawerForLp } from "../DrawerForLp"
import { Buttons } from "./parts/Buttons"
import { Links } from "./parts/Links"

export const HeaderForLp: FC = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const { xs, lg } = useBreakpoint()

  return (
    <>
      <div style={{
        height: lg ? "80px" : "56px",
        display: "flex",
        alignItems: "center",
        padding: lg ? "16px 64px" : "16px",
        position: xs ? "unset" : "sticky",
        top: 0,
        zIndex: 1,
        background: "white"
      }}>
        <Link href={urls.lp}>
          <a>
            <Image src="/lp/artX_logo_black.png" height={lg ? "32px" : "22px" } width={lg ? "164.1px" : "112.82px" } />
          </a>
        </Link>
        {lg && (
          <div style={{ display: "flex", alignItems: "center", gap: "32px", marginLeft: "auto" }}>
            <Links />
            <Buttons />
          </div>
        )}
        {!lg && (
          <div onClick={() => setIsOpenDrawer(true)} style={{ marginLeft: "auto", height: "24px" }}>
            <MenuFilledIcon style={{ fontSize: "20px", fill: "black" }} />
          </div>
        )}
      </div>
      <DrawerForLp isOpenDrawer={isOpenDrawer} onClose={() => setIsOpenDrawer(false)} />
    </>
  )
}

