import { Typography } from "antd"
import { urls } from "helpers/urls"
import Link from "next/link"
import { FC } from "react"
import styled from "styled-components"

export const Links: FC = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "24px", marginLeft: "auto" }}>
      <Link href={urls.lp}>
        <StyledHeaderAnchor>
          <Typography.Title level={3}>
            トップ
          </Typography.Title>
        </StyledHeaderAnchor>
      </Link>
      {/* NOTE: 機能一覧のページができるまでは非表示にしておく */}
      {/* <Link href={urls.lp("features")}>
        <StyledHeaderAnchor>
          <Typography.Title level={3}>
            機能一覧
          </Typography.Title>
        </StyledHeaderAnchor>
      </Link> */}
      <Link href={urls.lp_case}>
        <StyledHeaderAnchor>
          <Typography.Title level={3}>
            導入事例
          </Typography.Title>
        </StyledHeaderAnchor>
      </Link>
      <StyledHeaderAnchor href="https://cloud.artx.com/sign_in" target="_blank">
        <Typography.Title level={3}>
          ログイン
        </Typography.Title>
      </StyledHeaderAnchor>
    </div>
  )
}

const StyledHeaderAnchor = styled.a`
  &:hover {
    opacity: 0.6;
    transition: .2s;
  }
`
