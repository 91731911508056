import { Button, Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import colors from "helpers/colors"
import { urls } from "helpers/urls"
import Link from "next/link"
import { FC } from "react"
import styled from "styled-components"

export const TextPart: FC = () => {
  const { xs } = useBreakpoint()

  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: "28px", width: xs ? "100%" : "420px" }}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: xs ? "center" : "start" }}>
        <Typography.Title level={3} style={{ color: "white", marginBottom: "4px" }}>
          日本初！アートギャラリー向け管理システム！
        </Typography.Title>
        <Typography.Title level={1} style={{ color: "white" }}>
          事務作業を最大98％削減
        </Typography.Title>
        <Typography.Title level={1} style={{ color: "white" }}>
          ギャラリーのための管理クラウド
        </Typography.Title>
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "start", gap: "24px" }}>
        <Link href={urls.document_request_for_lp}>
          <StyledDefaultButton type="default" style={{ fontSize: "16px", lineHeight: 1.5, fontWeight: 700, flex: xs ? 1 : "none" }}>
            資料請求
          </StyledDefaultButton>
        </Link>
        <Link href={urls.contact_for_lp}>
          <StyledDefaultButton type="default" style={{ fontSize: "16px", lineHeight: 1.5, fontWeight: 700, flex: xs ? 1 : "none" }}>
            お問い合わせ
          </StyledDefaultButton>
        </Link>
      </div>
    </div>

  )
}

const StyledDefaultButton = styled(Button)`
  height: 48px;
  width: 144px;
  border-radius: 40px;
  border-color: ${colors.primary};

  &:hover {
    background: ${colors.primary};
    color: white;
  }
`

