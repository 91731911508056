import { Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import Image from "next/image"
import { VFC } from "react"

type Props = {
  inquiryType: "document" | "contact"
}

export const InquiryComplete: VFC<Props> = ({ inquiryType }) => {
  const { xs } = useBreakpoint()

  return (
    <div style={{ padding: xs ? "48px 0px" : "80px 64px", background: "#ECECEC", position: "relative", overflow: "hidden" }}>
      <div
        style={{
          position: "absolute",
          background: "#E5E5E5",
          width: xs ? "100%" : "1069px",
          height: xs ? "381.39px" : "876px",
          top: "0px",
          right: "0px",
          clipPath: "polygon(0% 0, 100% 0%, 100% 100%)" }}
      />
      <div style={{ position: "relative", display: "flex", flexDirection: xs ? "column" : "row", gap: xs ? "40px" : "48px" }}>
        <div style={{ margin: "0 auto", width: xs ? "100%" : "472px", padding: xs ? "0px 16px" : "0px", textAlign: xs ? "left" : "center" }}>
          <Typography.Title level={1} style={{ marginBottom: "16px" }}>
            {inquiryType === "contact" ? "お問い合わせ" : "資料請求"}が完了しました
          </Typography.Title>
          <Typography.Title level={3}>
            {inquiryType === "contact" ? "お問い合わせ" : "資料請求を"}ありがとうございます。
          </Typography.Title>
          <Typography.Title level={3}>
            内容確認後、通常3営業日以内に担当者よりご連絡をいたします。
          </Typography.Title>
          <div style={{ textAlign: "center", marginTop: xs ? "40px" : "80px" }}>
            <Image
              src="/lp/contact/customer_service_flatline.png"
              alt="customer_service_flatline.png"
              width={xs ? 280 : 310.11}
              height={xs ? 196.39 : 217.52}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

