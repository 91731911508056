import { Button, Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import { Entry } from "contentful"
import colors from "helpers/colors"
import { urls } from "helpers/urls"
import { IArticle } from "libs/contentful"
import Link from "next/link"
import { VFC } from "react"
import styled from "styled-components"

import { CompanyCaseCard } from "./parts/CompanyCaseCard"

type Props = {
  articles: Entry<IArticle>[]
}

export const CompanyCasesSectionForLp: VFC<Props> = ({ articles }) => {
  const { xs } = useBreakpoint()

  return (
    <div style={{ padding: "80px 16px", position: "relative", overflow: "hidden" }}>
      <div
        style={{
          position: "absolute",
          background: "#ECECEC",
          width: xs ? "100%" : "759px",
          height: xs ? "336.69px" : "682px",
          top: "0px",
          left: "0px",
          clipPath: "polygon(0% 0, 100% 0%, 0% 100%)" }}
      />
      <div style={{ margin: "0 auto", width: "fit-content", position: "relative", textAlign: "center" }}>
        <Typography.Title level={1} id="company_cases" style={{ paddingTop: xs ? "24px" : "80px", marginTop: xs ? "-24px" : "-80px" }}>
          導入事例
        </Typography.Title>
        <div style={{ maxWidth: "865px", marginTop: "40px" }}>
          <div style={{ display: "flex", flexDirection: xs ? "column" : "row", gap: "32px" }}>
            {articles.map(article => {
              return (
                <CompanyCaseCard
                  key={article.sys.id}
                  link={urls.lp_case_details(article.sys.id)}
                  imageUrl={article.fields.thumbnail.fields.file.url}
                  title={article.fields.title}
                  companyName={article.fields.companyName}
                  representativeName={article.fields.representativeName}
                />
              )
            })}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "24px", marginTop: "40px" }}>
          <Link href={urls.lp_case}>
            <StyledDefaultButton type="default" style={{ padding: "16px 24px", fontSize: "16px", lineHeight: 1, fontWeight: 700, flex: xs ? 1 : "none" }}>
              導入事例をもっと見る
            </StyledDefaultButton>
          </Link>
        </div>
      </div>
    </div>
  )
}

const StyledDefaultButton = styled(Button)`
  height:48px;
  border-radius: 40px;
  border-color: ${colors.primary};

  &:hover {
    background: ${colors.primary};
    color: white;
  }
`

