import { Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import { FC } from "react"

type Props = {
  number: string
}

export const IntroductionStep:FC<Props> = ({ number, children }) => {
  const { xs } = useBreakpoint()

  return (
    <div
      style={{
        background: "#F5F5F5",
        textAlign: "center",
        width: xs ? "108px" : "140px",
        height: xs ? "108px" : "140px",
        borderRadius: "70px",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Typography.Text
        style={{
          position: "absolute",
          fontFamily: "Futura",
          fontWeight: 500,
          fontSize: xs ? 20 : 24,
          lineHeight: 1.5,
          top: xs ? "-15px" : "-18px",
          left: 0,
          right: 0,
          margin: "auto",
          fontStyle: "italic"
        }}
      >
        {number}
      </Typography.Text>
      <Typography.Text style={{ fontWeight: 700, fontSize: xs ? 12 : 14, lineHeight: 1.5 }}>
        {children}
      </Typography.Text>
    </div>
  )
}
