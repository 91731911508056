import { Button, Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import colors from "helpers/colors"
import { urls } from "helpers/urls"
import Image from "next/image"
import Link from "next/link"
import { VFC } from "react"
import styled from "styled-components"

export const AppealSectionForLp:VFC = () => {
  const { xs } = useBreakpoint()

  return (
    <div style={{ width: "100%", height: xs ? 275 : 248, position: "relative", padding: xs ? "48px 16px" : "60px" }}>
      <Image
        src={"/pc-cta-bg.svg"}
        alt={"cta background"}
        layout='fill'
        objectFit="cover"
      />
      <div style={{ margin: "0 auto", width: "fit-content", position: "relative", textAlign: "center" }}>
        <Typography.Title level={1} style={{ color: "white" }}>
            ArtXCloudを導入して、{xs && <br/>}事務作業を最大98％{xs && <br/>}削減しませんか？
        </Typography.Title>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "24px", marginTop: "40px" }}>
          <Link href={urls.document_request_for_lp}>
            <StyledDefaultButton type="default" style={{ fontSize: "16px", lineHeight: 1.5, fontWeight: 700, flex: xs ? 1 : "none" }}>
              資料請求
            </StyledDefaultButton>
          </Link>
          <Link href={urls.contact_for_lp}>
            <StyledDefaultButton type="default" style={{ fontSize: "16px", lineHeight: 1.5, fontWeight: 700, flex: xs ? 1 : "none" }}>
              お問い合わせ
            </StyledDefaultButton>
          </Link>
        </div>
      </div>
    </div>
  )
}

const StyledDefaultButton = styled(Button)`
  height: 48px;
  width: 144px;
  border-radius: 40px;
  border-color: ${colors.primary};

  &:hover {
    background: ${colors.primary};
    color: white;
  }
`

