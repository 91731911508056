import { Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import { VFC } from "react"

import { ResolveCard } from "./parts/ResolveCard"

export const ResolveSectionForLp: VFC = () => {
  const { xs } = useBreakpoint()

  return (
    <div style={{ padding: xs ? "64px 16px" : "64px 16px", textAlign: "center", background: "#ECECEC", position: "relative" }}>
      <div
        style={{
          position: "absolute",
          background: "#E5E5E5",
          width: xs ? "100%" : "892px",
          height: xs ? "801px" : "801.5px",
          right: "0px",
          top: "0px",
          clipPath: "polygon(0% 0, 100% 0%, 100% 100%)"
        }}
      />
      <div
        style={{
          position: "absolute",
          background: "#E5E5E5",
          width: xs ? "100%" : "979.5px",
          height: xs ? "947px" : "947.5px",
          left: "0px",
          bottom: "0px",
          clipPath: "polygon(0% 0, 100% 100%, 0% 100%)" }}
      />
      <Typography.Title level={1} style={{ position: "relative" }}>
        こんなお悩みありませんか？
      </Typography.Title>
      <div style={{ maxWidth: "858px", margin: "64px auto 0px", display: "flex", flexDirection: "column", gap: xs ? "48px" : "80px" }}>
        <div style={{ maxWidth: xs ? "100%" : "711px", marginLeft: "auto" }}>
          <ResolveCard
            imagePath="/lp/resolve/Illust_Resolve_01.png"
            ballonText="作品リストやカタログの作成に数時間かかる..."
            title1="作品リストやカタログ、"
            title2="ラベルの印刷までが3ステップで完了。"
            description="ArtXCloudは作品やアーティストのデータなどの各種データを一括管理し、印刷用レイアウトを完備しているため、簡単に作品リストやカタログなどの印刷物を作ることが可能です。"
          />
        </div>
        <div style={{ maxWidth: xs ? "100%" : "711px", marginRight: "auto" }}>
          <ResolveCard
            imagePath="/lp/resolve/Illust_Resolve_02.png"
            ballonText="データ間でのコピペが多くてミスが多い..."
            title1="コピペなしで操作が完結。"
            title2="作業時間もミスも大幅減に。"
            description="ArtXCloudはギャラリー業務に最適化したデザインで開発しております。このため、余計なコピペ業務がなくなり、簡潔かつ効率的な操作性を実現しています。"
          />
        </div>
        <div style={{ maxWidth: xs ? "100%" : "711px", marginLeft: "auto" }}>
          <ResolveCard
            imagePath="/lp/resolve/Illust_Resolve_03.png"
            ballonText="自社でシステムを作るにはコストがかかる..."
            title1="初期費用0円〜。"
            title2="低額の月額利用料だけで最新機能を利用可能。"
            description="ArtXCloudはクラウドサービスのため、初期費用0円からご利用頂けます。また常にシステムを改善しているため、高額なシステム更新費なしで最新機能を使うことが可能です。"
          />
        </div>
      </div>
    </div>
  )
}
