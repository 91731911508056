import { Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import { VFC } from "react"

import { QuestionItem } from "./parts/QuestionItem"

export const QuestionSectionForLp: VFC = () => {
  const { xs } = useBreakpoint()

  return (
    <div style={{ padding: "80px 16px 80px", background: "#ECECEC", position: "relative" }}>
      <div
        style={{
          position: "absolute",
          background: "#E5E5E5",
          width: xs ? "100%" : "1069px",
          height: xs ? "337.1px" : "876px",
          bottom: "0px",
          right: "0px",
          clipPath: "polygon( 100% 0%, 100% 100%, 0% 100%)" }}
      />
      <div style={{ margin: "0 auto", width: "fit-content", position: "relative", textAlign: "center" }}>
        <Typography.Title level={1}>
          よくある質問
        </Typography.Title>
      </div>
      <div style={{ padding: xs ? "32px 16px" : "32px 48px", maxWidth: "858px", background: "white", margin: "40px auto 0px", position: "relative" }}>
        <QuestionItem
          questionText="現在使っているシステムやExcelから移行できますか？"
          answerText="可能です。データをご準備いただいてから最短営業日5日で移行できます。"
        />
        <QuestionItem
          questionText="データ移行をお願いする際に何を準備したら良いでしょうか？"
          answerText="現在お使いのシステムよりExcelかCSVデータをご準備いただき、メールかオンラインストレージ経由で共有いただいております。弊社担当が手厚くサポートいたします。"
        />
        <QuestionItem
          questionText="追加機能やカスタマイズを依頼できますか？"
          answerText="可能です。ただし内容によっては追加費用が発生したり、お受けできない場合があります。"
        />
        <QuestionItem
          questionText="システムを使いこなせるか不安です。"
          answerText={<>導入後3ヶ月は毎月お打ち合わせの時間をいただき、お客様に使っていただけるようサポートしています。<br/>それ以外にもお問い合わせいただければ、いつでも弊社担当が手厚くサポートします。</>}
        />
        <QuestionItem
          questionText="契約書を締結する必要はありますか？"
          answerText={<>必要ありません。<br />利用規約とプライバシーポリシーに同意するだけでご利用開始いただけます。</>}
        />
        <QuestionItem
          questionText="ArtXCloudの利用をやめたいときにデータ移行できますか？"
          answerText="可能です。まずはお気軽に相談してください。"
        />
      </div>
    </div>
  )
}

